<template>
  <div class="animate">
    <b-row>
      <b-col lg="12 mt-1">
        <div class="card">
          <b-row v-if="errorMessage" class="justify-content-center mt-2">
            <b-col lg="10">
              <b-alert variant="danger" show dismissible>
                {{ errorMessage }}
              </b-alert>
            </b-col>
          </b-row>
          <b-row v-if="isError" class="justify-content-center mt-2">
            <b-col lg="10">
              <b-alert variant="danger" show dismissible>
                Something went wrong
                <br />
                {{ errorMessage }}
              </b-alert>
            </b-col>
          </b-row>
          <div class="card-body">
            <OrderFilter
              @start="timeStart"
              @end="timeEnd"
              @paymentgateway="payment"
              @clientid="client"
              @ordernumber="order_number"
              @orderStatus="order_status"
              @isRenewal="status_renewal"
              :totalRows="totalRows"
              :paymentOption="paymentOption"
              :isLoading="isLoading"
            />
            <OrderTable
              :items="items"
              :isLoading="isLoading"
              :perPage="perPage"
              :currentPage="currentPage"
            />
          </div>
          <b-row class="justify-content-start ml-1">
            <b-col lg="3">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                aria-controls="my-table"
              />
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
// import OrderTable from "../../components/orders/OrderTable.vue"
import OrderFilter from '../../components/orders/OrderFilter.vue';
import OrderTable from './table.vue';
export default {
  name: 'ordersList',
  components: {
    OrderTable,
    OrderFilter,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 50,
      filter: {},
      isLoadingSearch: false,
      items_search: [],
      show: false,
      details: [],
      range: {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      paymentgatewayID: [],
      clientID: '',
      ordernumber: '',
      orderStatus: '',
      paymentOption: [],
      isRenewal: '',
    };
  },
  watch: {
    payments: function() {
      if (!Object.keys(this.payments).length) return;
      this.paymentOption = this.payments;
    },
    currentPage: function() {
      this.actionGetSales();
    },
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Transactions | Content Management System Ebooks Gramedia.com';
      },
    },
  },
  computed: {
    ...mapState({
      payments: (state) => state.payments.items,
      isLoading: (state) => state.orders.isLoading,
      isLodingBtnSearch: (state) => state.orders.isLodingBtnSearch,
      isError: (state) => state.orders.isError,
      totalRows: (state) => state.orders.totalRows,
      errorMessage: (state) => state.orders.ErrorMessage,
    }),
    ...mapGetters('orders', { items: 'orders' }),
  },
  mounted() {
    this.actionGetSales();
    this.actionGetPayment();
  },
  methods: {
    ...mapActions('orders', [
      'fetchOrders',
      'patchOrders',
      'searchOrdersBydate',
    ]),
    ...mapActions('payments', ['fetchAllPayment']),
    actionGetSales() {
      let payload = {
        from: this.range.start,
        to: this.range.end,
        page: this.currentPage,
        limit: this.perPage,
        payment: this.paymentgatewayID
          ? this.paymentgatewayID.map((val) => val.id)
          : '',
        clientId: this.clientID,
        orderStatus: this.orderStatus,
      };

      this.fetchOrders(payload);
      payload.payment = this.paymentgatewayID
        ? this.paymentgatewayID.map((val) => `${val.id}#${val.name}`)
        : '';
      this.setQuery(payload);
    },
    formatPriceBasic(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    actionPatchOrder(orders) {
      this.patchOrders(orders);
    },

    actionGetPayment() {
      this.isLoadingPayment = true;
      if (!this.paymentOption.length) {
        const payload = {
          status: true,
        };
        this.fetchAllPayment(payload);
      }

      this.isLoadingPayment = false;
    },

    setQuery(q = {}) {
      this.$router.push({ query: q }).catch(() => {});
    },

    timeStart(start) {
      this.range.start = moment(start).format('YYYY-MM-DD');
    },
    timeEnd(end) {
      this.range.end = moment(end).format('YYYY-MM-DD');
    },
    payment(paymentgateway) {
      this.paymentgatewayID = paymentgateway;
    },
    client(clientid) {
      this.clientID = clientid;
    },
    order_number(ordernumber) {
      this.ordernumber = ordernumber;
    },
    order_status(orderStatus) {
      this.orderStatus = orderStatus;
    },
    status_renewal(isRenewal) {
      this.isRenewal = isRenewal;
    },
  },
};
</script>
<style scoped>
.animate {
  font-family: 'Avenir Next', 'effra', Helvetica, Arial, sans-serif;
}
.tableFixHead {
  overflow-y: auto;
  height: 580px;
}
.tableFixHead thead th {
  position: sticky;
  top: 20;
}
.date {
  width: 170px;
}
</style>
