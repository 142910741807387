var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animate"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12 mt-1"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_vm.errorMessage ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")])], 1)], 1) : _vm._e(), _vm.isError ? _c('b-row', {
    staticClass: "justify-content-center mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "10"
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": "",
      "dismissible": ""
    }
  }, [_vm._v(" Something went wrong "), _c('br'), _vm._v(" " + _vm._s(_vm.errorMessage) + " ")])], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "card-body"
  }, [_c('OrderFilter', {
    attrs: {
      "totalRows": _vm.totalRows,
      "paymentOption": _vm.paymentOption,
      "isLoading": _vm.isLoading
    },
    on: {
      "start": _vm.timeStart,
      "end": _vm.timeEnd,
      "paymentgateway": _vm.payment,
      "clientid": _vm.client,
      "ordernumber": _vm.order_number,
      "orderStatus": _vm.order_status,
      "isRenewal": _vm.status_renewal
    }
  }), _c('OrderTable', {
    attrs: {
      "items": _vm.items,
      "isLoading": _vm.isLoading,
      "perPage": _vm.perPage,
      "currentPage": _vm.currentPage
    }
  })], 1), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }