var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-right"
  }, [_c('h4', {
    staticClass: "h4 text-center p-1"
  }, [_vm._v("Transaction")]), _c('b-table', {
    attrs: {
      "id": "table-transition",
      "sticky-header": "500px",
      "show-empty": "",
      "busy": _vm.isLoading,
      "isLoading": _vm.isLoading,
      "fields": _vm.fields,
      "hover": "",
      "responsive": "xl",
      "tbody-transition-props": _vm.transProps,
      "items": _vm.items,
      "sort-icon-left": "",
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc
    },
    on: {
      "update:sortBy": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function ($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function ($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function ($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "head()",
      fn: function (scope) {
        return [_c('div', [_vm._v(" " + _vm._s(scope.label) + " ")])];
      }
    }, {
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(id)",
      fn: function (row) {
        return [_c('router-link', {
          staticClass: "link",
          attrs: {
            "to": {
              path: '/order/detail?orderid=' + row.item.id
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.id) + " ")])];
      }
    }, {
      key: "cell(email)",
      fn: function (row) {
        return [row.item.users ? _c('div', [_c('router-link', {
          attrs: {
            "to": {
              path: '/customers/detail/' + row.item.users.email
            },
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(row.item.users.email) + " ")])], 1) : _vm._e()];
      }
    }, {
      key: "cell(final_amount)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatPrice(row.item.final_amount, row.item.currency_code)) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function (row) {
        return [_c('paymentview', {
          attrs: {
            "payment": row.item.order_status
          }
        })];
      }
    }, {
      key: "cell(payment)",
      fn: function (row) {
        return [_c('gatewayview', {
          attrs: {
            "gateway": row.item.paymentgateway_id
          }
        })];
      }
    }, {
      key: "cell(Client)",
      fn: function (row) {
        return [_c('Client', {
          attrs: {
            "client": row.item.client_id
          }
        })];
      }
    }, {
      key: "cell(isrenewal)",
      fn: function (row) {
        return [row.item.is_renewal ? _c('span', {
          staticClass: "badge badge-pill badge-primary"
        }, [_vm._v(" Renewal ")]) : _c('span', {
          staticClass: "badge badge-pill badge-success"
        }, [_vm._v(" Non-Renewal ")])];
      }
    }, {
      key: "cell(is_active)",
      fn: function (row) {
        return [!row.item.is_active ? _c('span', {
          staticClass: "badge badge-pill badge-warning"
        }, [_vm._v(" Testing ")]) : _c('span', {
          staticClass: "badge badge-pill badge-primary"
        }, [_vm._v(" Production ")])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function (row) {
        return [_c('div', {
          staticStyle: {
            "width": "fit-content",
            "min-width": "12em"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(row.item.created)) + " ")])];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('svg', {
          staticClass: "heart",
          attrs: {
            "viewBox": "0 0 32 29.6"
          }
        }, [_c('path', {
          attrs: {
            "d": "M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2\n            c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
          }
        })]), _vm._v(" Loading ... ")])];
      },
      proxy: true
    }, {
      key: "cell(show_details)",
      fn: function (row) {
        return [_c('b-button', {
          staticClass: "mr-2 btn-success",
          attrs: {
            "size": "sm"
          },
          on: {
            "click": row.toggleDetails
          }
        }, [_vm._v(" " + _vm._s(row.detailsShowing ? 'Hide' : 'Show') + " ")])];
      }
    }, {
      key: "row-details",
      fn: function (row) {
        return [_c('div', [_c('table', [_c('thead', [_c('tr', [_c('th', [_vm._v("Orderline key")]), _c('th', [_vm._v("Name")]), _c('th', [_vm._v("Qty")]), _c('th', [_vm._v("Price")]), _c('th', [_vm._v("Discount")]), _c('th', [_vm._v("Final Price")]), _c('th')])]), _vm._l(row.item.orderlines, function (line) {
          return _c('tbody', {
            key: line.id
          }, [_c('tr', {
            staticClass: "text-left"
          }, [_c('td', [_vm._v(_vm._s(line.id))]), _c('td', [_vm._v(_vm._s(line.name))]), _c('td', [_vm._v(_vm._s(line.quantity))]), _c('td', [_vm._v(" " + _vm._s(_vm.formatPrice(line.price, row.item.currency_code)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.getDiscountPrice(line.price, line.final_price, line.currency_code, row.item.currency_code, line.quantity)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.formatPrice(line.final_price, row.item.currency_code)) + " ")]), _c('td', [_c('h5', [row.item.client_id == _vm.clientGramedia && _vm.getDiscountPrice(line.price, line.final_price, line.currency_code, row.item.currency_code, line.quantity) != 0 ? _c('span', {
            staticClass: "btn badge badge-primary rounded",
            on: {
              "click": function ($event) {
                return _vm.actionUpdateOfs(line.offer_id);
              }
            }
          }, [_vm.btnUpdateOfs ? _c('div', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status"
            }
          }, [_c('span', {
            staticClass: "sr-only"
          }, [_vm._v("Loading...")])]) : _c('div', [_c('i', {
            staticClass: "fa fa-send"
          }), _vm._v(" Update OFS ")])]) : _vm._e()])])])]);
        })], 2)])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }