<template>
  <div class="card border-0">
    <b-overlay :show="isLoading">
      <div class="row">
        <label for="" class="col-sm-2"> Search Order <br /> </label>
        <div class="col-sm-4">
          <input
            v-model="message"
            type="text"
            class="form-control"
            placeholder="type order number or order ID..."
            @input="debounceSearch"
          />
          <span v-if="typing"> <em> searching.. </em> </span>
        </div>
        <div class="col-sm-3">
          <multiselect
            v-model="orderStatus"
            label="text"
            track-by="text"
            placeholder="find Order Status"
            open-direction="bottom"
            :options="orderStatusOption"
            :searchable="true"
            :loading="isLoadingOrderStatus"
            :close-on-select="true"
            @input="changeSelectBox"
          >
            <span slot="noResult">
              Oops! No elements found. Consider changing the search query.
            </span>
          </multiselect>
        </div>
        <div class="col-sm-2">
          <button
            v-if="buttonShow"
            class="btn btn-primary"
            @click="searchOrder"
          >
            <i class="fa fa-search"></i> Search
          </button>
        </div>
        <div class="col-md-12 mt-3">
          <div class="form-group row">
            <span class="col-sm-2">Select Range</span>
            <div class="col-sm-4">
              <v-date-picker
                v-model="range"
                mode="date"
                :masks="masks"
                :max-date="new Date()"
                is-range
                @input="changeSelectBox"
              >
                <template v-slot="{ inputValue, inputEvents, isDragging }">
                  <div class="rows">
                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1 mx-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                      :max-date="new Date()"
                    />

                    <!-- <span class="p-1">to</span> -->

                    <input
                      class="flex-grow pl-8 pr-2 py-1 bg-gray-100 border rounded w-full p-1"
                      :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                      :max-date="new Date()"
                    />
                  </div>
                </template>
              </v-date-picker>
            </div>
            <div class="col-sm-3">
              <multiselect
                v-model="isRenewal"
                label="text"
                track-by="text"
                placeholder="renewal or non-renewal"
                open-direction="bottom"
                :options="isRenewalOptions"
                :searchable="true"
                :loading="isLoadingRenewal"
                :close-on-select="true"
                @input="changeSelectBox"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group row">
            <div class="col-sm-2">Select Payment Gateway</div>
            <div class="col-sm-4">
              <multiselect
                v-model="payment"
                label="name"
                track-by="name"
                placeholder="double click to get payment gateway"
                open-direction="bottom"
                :options="paymentOption"
                :searchable="true"
                :loading="isLoadingPayment"
                :close-on-select="false"
                :multiple="true"
                @input="changeSelectBox"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>
            <div class="col-sm-3">
              <multiselect
                v-model="client"
                label="text"
                track-by="text"
                placeholder="find Client ID"
                open-direction="bottom"
                :options="clientOption"
                :searchable="true"
                :loading="isLoadingClient"
                :close-on-select="true"
                @input="changeSelectBox"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </div>
            <div class="col-sm-2 text-center">
              Total :
              <strong>
                {{ formatNumber(totalRows) }}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import constant from '../../store/constant';

const clientID = Object.entries(constant.CLIENT_ID).map((val) => ({
  text: val[0].replace('_', ' '),
  value: val[1],
}));

const orderStatuses = Object.entries(constant.ORDERSTATUS).map((val) => ({
  text: val[0].replace('_', ' '),
  value: val[1],
}));

const statusRenewal = [
  {
    text: 'Renewal',
    value: true,
  },
  {
    text: 'Non Renewal',
    value: false,
  },
];

export default {
  name: 'OrderFilter',
  props: {
    totalRows: {
      type: Number,
      required: true,
    },
    paymentOption: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      buttonShow: false,
      currentPage: 1,
      perPage: 50,
      filter: '',
      items_search: [],
      isLoadingSearch: false,
      isLoadingBtnSearch: false,
      isLodingBtnSearch: false,
      range: {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD'),
      },
      masks: {
        input: 'YYYY-MM-DD',
      },
      isLoadingPayment: false,
      payment: [],
      isLoadingClient: false,
      client: '',
      clientOption: [...clientID],
      message: null,
      typing: null,
      orderStatus: '',
      orderStatusOption: [...orderStatuses],
      isLoadingOrderStatus: false,
      isRenewalOptions: [...statusRenewal],
      isRenewal: '',
      isLoadingRenewal: false,
    };
  },
  watch: {},
  methods: {
    ...mapActions('orders', [
      'searchOrders',
      'searchOrdersBydate',
      'searchOrderNumber',
    ]),

    debounceSearch(event) {
      this.buttonShow = false;
      this.payment = '';
      this.orderStatus = '';
      this.isRenewal = '';
      this.typing = 'You are typing';
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.typing = null;
        this.message = event.target.value;
        const payload = {
          from: this.range.start,
          to: this.range.end,
          page: this.currentPage,
          limit: this.perPage,
          ordernumber: this.message,
          clientId: this.client?.value,
        };
        this.searchOrderNumber(payload);
      }, 600);
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    formatNumber(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },

    changeSelectBox() {
      this.buttonShow = true;
    },

    setQuery(q = {}) {
      this.$router.push({ query: q }).catch(() => {});
    },

    searchOrder() {
      this.$emit('start', this.range.start);
      this.$emit('end', this.range.end);
      this.$emit('paymentgateway', this.payment);
      this.$emit('clientid', this.client?.value);
      this.$emit('orderStatus', this.orderStatus?.value);
      this.$emit('isRenewal', this.isRenewal?.value);
      if (this.client?.value != constant.CLIENT_ID.GRAMEDIA) {
        this.message = '';
      }

      const payload = {
        from: moment(this.range.start).format('YYYY-MM-DD'),
        to: moment(this.range.end).format('YYYY-MM-DD'),
        page: this.currentPage,
        limit: this.perPage,
        payment: this.payment ? this.payment.map((val) => val.id) : '',
        clientId: this.client?.value,
        orderStatus: this.orderStatus?.value,
        ordernumber: this.message,
        isRenewal: this.isRenewal?.value,
      };
      this.searchOrdersBydate(payload);
      payload.payment = this.payment
        ? this.payment.map((val) => `${val.id}#${val.name}`)
        : '';
      this.setQuery(payload);
      this.buttonShow = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
