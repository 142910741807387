<template>
  <div class="text-right">
    <h4 class="h4 text-center p-1">Transaction</h4>
    <b-table
      id="table-transition"
      sticky-header="500px"
      show-empty
      :busy="isLoading"
      :isLoading="isLoading"
      :fields="fields"
      hover
      responsive="xl"
      :tbody-transition-props="transProps"
      :items="items"
      sort-icon-left
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template #head()="scope">
        <div>
          {{ scope.label }}
        </div>
      </template>
      <template #cell(No)="row">
        {{ (currentPage - 1) * perPage + (row.index + 1) }}
      </template>
      <template v-slot:cell(id)="row">
        <router-link
          :to="{ path: '/order/detail?orderid=' + row.item.id }"
          class="link"
        >
          {{ row.item.id }}
        </router-link>
      </template>
      <template #cell(email)="row">
        <div v-if="row.item.users">
          <router-link
            :to="{ path: '/customers/detail/' + row.item.users.email }"
            target="_blank"
          >
            {{ row.item.users.email }}
          </router-link>
        </div>
      </template>
      <template #cell(final_amount)="row">
        {{ formatPrice(row.item.final_amount, row.item.currency_code) }}
      </template>
      <template #cell(status)="row">
        <paymentview :payment="row.item.order_status" />
      </template>
      <template #cell(payment)="row">
        <gatewayview :gateway="row.item.paymentgateway_id" />
      </template>
      <template #cell(Client)="row">
        <Client :client="row.item.client_id" />
      </template>
      <template #cell(isrenewal)="row">
        <span v-if="row.item.is_renewal" class="badge badge-pill badge-primary">
          Renewal
        </span>
        <span v-else class="badge badge-pill badge-success">
          Non-Renewal
        </span>
      </template>
      <template #cell(is_active)="row">
        <span v-if="!row.item.is_active" class="badge badge-pill badge-warning">
          Testing
        </span>
        <span v-else class="badge badge-pill badge-primary">
          Production
        </span>
      </template>
      <template #cell(createdAt)="row">
        <div style="width: fit-content;min-width: 12em;">
          {{ formatDate(row.item.created) }}
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-dark my-2">
          <svg class="heart" viewBox="0 0 32 29.6">
            <path
              d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
              c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
            />
          </svg>
          Loading ...
        </div>
      </template>
      <template #cell(show_details)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2 btn-success">
          {{ row.detailsShowing ? 'Hide' : 'Show' }}
        </b-button>
      </template>

      <template #row-details="row">
        <div>
          <table>
            <thead>
              <tr>
                <th>Orderline key</th>
                <th>Name</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Final Price</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="line in row.item.orderlines" :key="line.id">
              <tr class="text-left">
                <td>{{ line.id }}</td>
                <td>{{ line.name }}</td>
                <td>{{ line.quantity }}</td>
                <td>
                  {{ formatPrice(line.price, row.item.currency_code) }}
                </td>
                <td>
                  {{
                    getDiscountPrice(
                      line.price,
                      line.final_price,
                      line.currency_code,
                      row.item.currency_code,
                      line.quantity,
                    )
                  }}
                </td>
                <td>
                  {{ formatPrice(line.final_price, row.item.currency_code) }}
                </td>
                <td>
                  <h5>
                    <span
                      class="btn badge badge-primary rounded"
                      v-if="
                        row.item.client_id == clientGramedia &&
                          getDiscountPrice(
                            line.price,
                            line.final_price,
                            line.currency_code,
                            row.item.currency_code,
                            line.quantity,
                          ) != 0
                      "
                      @click="actionUpdateOfs(line.offer_id)"
                    >
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                        v-if="btnUpdateOfs"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div v-else>
                        <i class="fa fa-send"></i>
                        Update OFS
                      </div>
                    </span>
                  </h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import paymentview from '../../components/payments/payment.vue';
import gatewayview from '../../components/payments/gateway.vue';
import Client from '../../components/client/client.vue';
import constant from '../../store/constant';
const clientGramedia = constant.CLIENT_ID.GRAMEDIA;
export default {
  name: 'TableReport',
  components: {
    paymentview,
    gatewayview,
    Client,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      clientGramedia,
      btnUpdateOfs: false,
      sortBy: 'id',
      sortDesc: true,
      transProps: {
        enterActiveClass: 'animated fadeIn',
        leaveActiveClass: 'animated fadeOut',
        name: 'flip-list',
      },
      fields: [
        { key: 'No', label: 'No' },
        { key: 'id', label: 'Order Key', sortable: true },
        {
          key: 'email',
          label: 'Email',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'order_number',
          label: 'Order Number',
        },
        {
          key: 'final_amount',
          label: 'Amount',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'status',
          label: 'Status',
          thClass: 'text-center',
        },
        {
          key: 'payment',
          label: 'Payment',
          thClass: 'pl-3',
        },
        {
          key: 'isrenewal',
          label: 'Renewal',
          thClass: 'pl-3',
        },
        {
          key: 'Client',
          label: 'Client',
          thClass: 'text-center',
        },
        {
          key: 'remote_order_number',
          label: 'Remote Order Number',
          variant: 'info',
        },
        {
          key: 'is_active',
          label: 'Environment',
          thClass: 'text-center',
        },
        { key: 'createdAt', label: 'Transaction Time' },
        {
          key: 'show_details',
          label: 'Show Details',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
    };
  },
  methods: {
    ...mapActions('orders', ['updateOfs']),

    formatDate(tgl) {
      return new Date(tgl).toString();
    },
    formatPrice(value, currency) {
      if (value) {
        let formatter = null;
        if (currency == 'USD') {
          formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
          });
        } else {
          formatter = new Intl.NumberFormat('en-ID', {
            style: 'currency',
            currency: currency,
          });
        }

        return formatter.format(value);
      }

      return '0';
    },
    formatNumber(value) {
      if (value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }
      return '0';
    },
    getDiscountPrice(totalAmount, finalAmount, currency, currencyOrder, qty) {
      if (!isNaN(totalAmount) && !isNaN(finalAmount)) {
        const discount =
          parseFloat(totalAmount) * parseInt(qty) - parseFloat(finalAmount);
        return this.formatPrice(
          parseFloat(discount),
          currency ? currency : currencyOrder,
        );
      }
      return '-';
    },

    actionUpdateOfs(offerID) {
      this.btnUpdateOfs = true;
      try {
        this.updateOfs({ offerID })
          .then(() => {
            this.alertMsg(
              'Item has been sent, may take a few minutes to be displayed',
              'success',
            );
            this.btnUpdateOfs = false;
          })
          .catch((err) => {
            if (err.response.status == 422) {
              err.response.data.errors.map((e) =>
                this.alertMsg(e.msg, 'error'),
              );
            } else {
              this.alertMsg(err.response.data.data.message, 'error');
            }
            this.btnUpdateOfs = false;
          });
      } catch (error) {
        console.log(error);
        this.alertMsg('Ops. Something Went Wrong !');
        this.btnUpdateOfs = false;
      }
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
